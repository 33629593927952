import { Tooltip } from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
  GridInitialState,
  GridColumnResizeParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import omitBy from 'lodash/omitBy';
import { parse } from 'qs';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  defaultGetCasesParameters,
  useGetCasesQuery,
} from '@bvi/admin-panel/entities/search/api-slice';
import {
  selectCases,
  selectCasesTotalCount,
} from '@bvi/admin-panel/entities/search/selectors';
import {
  formatAmount,
  formatRange,
} from '@bvi/admin-panel/feature/search/lib/formatters';
import { AbuseLevels } from '@bvi/admin-panel/feature/search/ui/case-table/abuse-levels';
import { BaseSortParameters } from '@bvi/api-interfaces/entity/base';
import {
  AbuseDetailsKeys,
  BaseCaseEntityProperties,
  LegalDetailsKeys,
  StaticResolutionProperties,
  StaticPropertyKey,
  VictimInfoKeys,
  ResolutionPublicityVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import {
  AdditionalFilterParameter,
  ICasesSort,
} from '@bvi/api-interfaces/request/case';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { useSearchContext } from '@bvi/cases-search';
import { FormDataGrid } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';
import { useNavigation } from '@bvi/navigation-utils';

import { PAGE_SIZE_OPTIONS } from '../../lib/constants';
import { IAdminSearchFilterFormData } from '../../lib/types';

import { ManageCaseMenu } from './case-menu';

export interface ICaseTableProperties {
  cases: Array<ICaseData>;
  total: number;
  isLoading?: boolean;
}

interface ICaseTableLocationParameters
  extends Record<keyof IAdminSearchFilterFormData, string> {
  sort?: ICasesSort;
}

interface ICaseDataRow extends GridValueGetterParams<ICaseData> {}

const PERSISTED_DATA_KEY = 'case-table';

interface ITablePersistedData {
  [key: string]: {
    width: number;
  };
}

const persistedTableData = JSON.parse(
  localStorage.getItem(PERSISTED_DATA_KEY) ?? '{}',
) as ITablePersistedData;

const handleChangeWidth = (parameters: GridColumnResizeParams) => {
  const { width, colDef } = parameters;
  const { field } = colDef;
  const data = {
    ...persistedTableData,
    [field]: {
      width,
    },
  };

  localStorage.setItem(PERSISTED_DATA_KEY, JSON.stringify(data));
};

const ColumnSizes = {
  MINI: 30,
  SMALL: 80,
  MEDIUM: 140,
  LARGE: 180,
  VERY_LARGE: 280,
};

const TABLE_COLUMNS: Array<GridColDef & { translationPath?: string }> = (
  [
    {
      field: `id`,
      valueGetter: ({ row }: ICaseDataRow) => row.id,
      headerName: 'claimNumber',
      width: ColumnSizes.MEDIUM,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: StaticPropertyKey.COUNTRY,
      valueGetter: ({ row }: ICaseDataRow) => row.country?.name,
      headerName: 'country',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.REGION,
      valueGetter: ({ row }: ICaseDataRow) => row.region?.name,
      headerName: 'region',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.STATE,
      valueGetter: ({ row }: ICaseDataRow) => row.state?.name,
      headerName: 'state',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.ORGANIZATION_TYPE,
      valueGetter: ({ row }: ICaseDataRow) => row.organizationType?.name,
      headerName: 'organizationType',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticPropertyKey.ORGANIZATION,
      valueGetter: ({ row }: ICaseDataRow) => row.organization?.name,
      headerName: 'organization',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticPropertyKey.DIOCESE,
      valueGetter: ({ row }: ICaseDataRow) => row.diocese?.name,
      headerName: 'subsidiary',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_DATE,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatDate(row.resolution?.resolutionDate),
      headerName: 'resolutionDate',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_TYPE,
      valueGetter: ({ row }: ICaseDataRow) => row.resolution?.type?.name,
      headerName: 'resolutionType',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_TOTAL,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatAmount(
          row.resolution?.total?.amount,
          row.resolution?.total?.currency.symbol,
        ),
      headerName: 'resolutionAmount',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_CLAIMANT_COUNT,
      valueGetter: ({ row }: ICaseDataRow) => row.resolution?.claimantsCount,
      headerName: 'numberOfVictims',
      width: ColumnSizes.SMALL,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_PER_CLAIMANT,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatAmount(
          row.resolution?.perClaimant?.amount,
          row.resolution?.perClaimant?.currency.symbol,
        ),
      headerName: 'amountPerVictim',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.GROUP,
      valueGetter: ({ row }: ICaseDataRow) => row.group?.name,
      headerName: 'group',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticResolutionProperties.RESOLUTION_DEFENSE_COSTS,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatAmount(
          row.resolution?.defenseCosts?.amount,
          row.resolution?.defenseCosts?.currency.symbol,
        ),
      headerName: 'defenseCosts',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `properties.${LegalDetailsKeys.LAWSUIT_FILED}`,
      headerName: 'lawsuit',
      width: ColumnSizes.MEDIUM,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[LegalDetailsKeys.LAWSUIT_FILED]?.value,
      translationPath: 'yesNo',
    },
    {
      field: StaticPropertyKey.LIABILITY_DEFENSES,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.liabilityDefenses?.map(({ name }) => name) ?? [],
      headerName: 'liabilityDefenses',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticPropertyKey.EXCEPTION,
      valueGetter: ({ row }: ICaseDataRow) => row.exception?.name,
      headerName: 'exceptions',
      width: ColumnSizes.LARGE,
    },
    {
      field: `properties.${VictimInfoKeys.GENDER}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[VictimInfoKeys.GENDER]?.value,
      headerName: 'gender',
      width: ColumnSizes.SMALL,
      translationPath: 'gender',
    },
    {
      field: `properties.${VictimInfoKeys.AGE_CATEGORY}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[VictimInfoKeys.AGE_CATEGORY]?.value,
      headerName: 'ageCategory',
      width: ColumnSizes.SMALL,
      translationPath: 'ageCategory',
    },
    {
      field: `properties.${VictimInfoKeys.AGE_AT_START_OF_ABUSE}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatRange(
          row.properties[VictimInfoKeys.AGE_AT_START_OF_ABUSE]?.value as string,
          row.properties[VictimInfoKeys.AGE_AT_END_OF_ABUSE]?.value as string,
        ),
      headerName: 'ageOfAbuse',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `properties.${AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatRange(
          row.properties[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]
            ?.value as string,
          row.properties[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]
            ?.value as string,
        ),
      headerName: 'instances',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `properties.${AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatRange(
          row.properties[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]
            ?.value as string,
          row.properties[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]
            ?.value as string,
        ),
      headerName: 'perpetrators',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.DAMAGE,
      valueGetter: ({ row }: ICaseDataRow) => row.damage?.name,
      headerName: 'damages',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: StaticPropertyKey.JOB_TITLE,
      valueGetter: ({ row }: ICaseDataRow) => row.jobTitle?.name,
      headerName: 'jobTitles',
      width: ColumnSizes.LARGE,
    },
    {
      field: StaticPropertyKey.ORDER,
      valueGetter: ({ row }: ICaseDataRow) => row.order?.name,
      headerName: 'order',
      width: ColumnSizes.LARGE,
    },
    {
      field: `properties.${VictimInfoKeys.BIRTH_DATE}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatDate(row.properties[VictimInfoKeys.BIRTH_DATE]?.value as string),
      headerName: 'victimBirthDate',
      width: ColumnSizes.LARGE,
    },
    {
      field: `properties.${VictimInfoKeys.STARTED_ABUSE_AT}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        formatRange(
          row.properties[VictimInfoKeys.STARTED_ABUSE_AT]?.value as string,
          row.properties[VictimInfoKeys.END_OF_ABUSE_AT]?.value as string,
          formatDate,
        ),
      headerName: 'abusePeriod',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `properties.${LegalDetailsKeys.DAMAGES_COMMENT}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[LegalDetailsKeys.DAMAGES_COMMENT]?.value,
      headerName: 'damagesComment',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `properties.${LegalDetailsKeys.OTHER_LIFE_STRESS}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[LegalDetailsKeys.OTHER_LIFE_STRESS]?.value,
      headerName: 'otherStresses',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: StaticResolutionProperties.NOTES,
      valueGetter: ({ row }: ICaseDataRow) => row.resolution?.notes,
      headerName: 'resolutionNotes',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `properties.${LegalDetailsKeys.HAS_ATTORNEY}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[LegalDetailsKeys.HAS_ATTORNEY]?.value,
      headerName: 'hasAttorney',
      width: ColumnSizes.SMALL,
    },
    {
      field: `properties.${LegalDetailsKeys.PREPARATOR}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[LegalDetailsKeys.PREPARATOR]?.value,
      headerName: 'perpetratorDeny',
      width: ColumnSizes.MEDIUM,
      translationPath: 'perpetrator',
    },
    {
      field: BaseCaseEntityProperties.DESCRIPTION,
      valueGetter: ({ row }: ICaseDataRow) => row.description,
      headerName: 'notes',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: StaticResolutionProperties.IS_PUBLIC,
      valueGetter: ({ row }: ICaseDataRow) => {
        if (row.resolution?.isPublic) {
          return ResolutionPublicityVariantsKey.PUBLIC;
        }

        return row.resolution?.isPublic === false
          ? ResolutionPublicityVariantsKey.CONFIDENTIAL
          : row.resolution?.isPublic;
      },
      headerName: 'publicOrConfidential',
      width: ColumnSizes.SMALL,
      translationPath: AdditionalFilterParameter.RESOLUTION_PUBLICITY,
    },
    {
      field: `properties.${AbuseDetailsKeys.LEVEL_1}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[AbuseDetailsKeys.LEVEL_1]?.value,
      renderCell: ({ value }) => <AbuseLevels variants={value} />,
      headerName: 'level1',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `properties.${AbuseDetailsKeys.LEVEL_2}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[AbuseDetailsKeys.LEVEL_2]?.value,
      renderCell: ({ value }) => <AbuseLevels variants={value} />,
      headerName: 'level2',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `properties.${AbuseDetailsKeys.LEVEL_3}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[AbuseDetailsKeys.LEVEL_3]?.value,
      renderCell: ({ value }) => <AbuseLevels variants={value} />,
      headerName: 'level3',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `properties.${AbuseDetailsKeys.LEVEL_4}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[AbuseDetailsKeys.LEVEL_4]?.value,
      renderCell: ({ value }) => <AbuseLevels variants={value} />,
      headerName: 'level4',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `properties.${AbuseDetailsKeys.LEVEL_5}`,
      valueGetter: ({ row }: ICaseDataRow) =>
        row.properties[AbuseDetailsKeys.LEVEL_5]?.value,
      renderCell: ({ value }) => <AbuseLevels variants={value} />,
      headerName: 'level5',
      width: ColumnSizes.VERY_LARGE,
    },
    {
      field: `createdAt`,
      valueGetter: ({ row }: ICaseDataRow) => formatDate(row.createdAt),
      headerName: 'createdAt',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: `updatedAt`,
      valueGetter: ({ row }: ICaseDataRow) => formatDate(row.updatedAt),
      headerName: 'updatedAt',
      width: ColumnSizes.MEDIUM,
    },
    {
      field: 'actions',
      valueGetter: ({ row }: ICaseDataRow) => row,
      hideable: false,
      headerName: '',
      renderCell: ({ value }) => <ManageCaseMenu case={value} />,
      width: ColumnSizes.MINI,
      sortable: false,
    },
  ] as Array<GridColDef & { translationPath?: string }>
).map((column) => ({
  ...column,
  width: persistedTableData[column.field]?.width ?? column.width,
}));

const CaseTableWrapper: React.FC = () => {
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as unknown as ICaseTableLocationParameters;

  const { data: casesData, isFetching: isFetchingCases } = useGetCasesQuery(
    searchParameters,
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const cases = selectCases(casesData);
  const totalCount = selectCasesTotalCount(casesData);

  const navigate = useNavigation();
  const { route } = useSearchContext();
  const { t } = useTranslation();

  const translatedColumns: Array<GridColDef> = TABLE_COLUMNS.map((column) => ({
    renderCell: (parameters) => {
      return (
        <Tooltip title={parameters.formattedValue}>
          <div>{parameters.formattedValue}</div>
        </Tooltip>
      );
    },
    ...column,
    headerName: column.headerName && t(`search.caseTable.${column.headerName}`),
    valueFormatter: ({ value }) =>
      column.translationPath && value
        ? t(`${column.translationPath}.${value}`)
        : value,
  }));

  const handleChangePagination = (paginationModel: GridPaginationModel) => {
    const { page, pageSize } = paginationModel;

    const currentPagesize = Number(searchParameters.limit);
    const parameters = {
      ...searchParameters,
      limit: pageSize,
      //reset page if limit is changed
      page: currentPagesize === pageSize ? page + 1 : 1,
    };

    navigate(route, parameters);
  };

  const handleChangeSorting = (parameters: GridSortModel) => {
    const sort = Object.fromEntries(
      parameters.flatMap((parameter, index) => {
        return [
          [
            parameter.field,
            {
              order: parameter?.sort?.toUpperCase(),
              priority: index,
            },
          ],
        ];
      }),
    );

    const updatedSorting = omitBy(
      sort,
      (field) => field.order === 'reset'.toUpperCase(),
    );

    navigate(route, { ...searchParameters, sort: updatedSorting });
  };

  const paginationModel = {
    page: searchParameters.page ? Number(searchParameters.page) - 1 : 0,
    pageSize: searchParameters.limit
      ? Number(searchParameters.limit)
      : defaultGetCasesParameters.limit,
  };

  const sortModel = Object.entries<BaseSortParameters>(
    searchParameters.sort ?? {},
  ).map(([field, sort]) => ({
    field,
    sort: sort.order.toLowerCase() as 'asc' | 'desc',
  }));

  const gridState = {
    pinnedColumns: { right: ['actions'] },
  } satisfies GridInitialState;

  return (
    <FormDataGrid
      initialState={gridState}
      columns={translatedColumns}
      rows={cases}
      rowCount={totalCount}
      pageSize={200}
      paginationModel={paginationModel}
      sortModel={sortModel}
      loading={isFetchingCases}
      rowHeight={26}
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      onPaginationModelChange={handleChangePagination}
      onSortModelChange={handleChangeSorting}
      onColumnWidthChange={handleChangeWidth}
      paginationMode="server"
      sortingMode="server"
      //@ts-expect-error TODO: Need to extend GridSortDirection type for reset on third sort?
      sortingOrder={['asc', 'desc', 'reset']}
      autoHeight
      pagination
      disableRowSelectionOnClick
      disableColumnFilter
    />
  );
};

export const CaseTable: React.FC = () => {
  return <CaseTableWrapper />;
};
